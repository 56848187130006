export const YunoSVGIcons: { [key: string]: string } = {
  AMERICAN_EXPRESS: 'american_express',
  DISCOVER: 'discover',
  MASTERCARD: 'mastercard',
  MAESTRO: 'maestro',
  VISA: 'visa',
  MERCADO_PAGO_CHECKOUT_PRO: 'mercadopagocheckoutpro',
  YAPPY: 'yappy',
  PIX: 'pix',
  YAPE: 'yape',
}

export const YunoAnalyticsPaymentType: { [key: string]: string } = {
  MERCADO_PAGO_CHECKOUT_PRO: 'mercado_pago_wallet',
} as const

/* Enrollment */

export const YunoEnrollmentStatus = {
  CREATED: 'CREATED',
  EXPIRED: 'EXPIRED',
  REJECTED: 'REJECTED',
  READY_TO_ENROLL: 'READY_TO_ENROLL',
  ENROLL_IN_PROCESS: 'ENROLL_IN_PROCESS',
  UNENROLL_IN_PROCESS: 'UNENROLL_IN_PROCESS',
  ENROLLED: 'ENROLLED',
  DECLINED: 'DECLINED',
  CANCELED: 'CANCELED',
  ERROR: 'ERROR',
  UNENROLLED: 'UNENROLLED',
} as const

export const YunoEnrollmentError = {
  CANCELED_BY_USER: 'CANCELED_BY_USER',
} as const

export type YunoEnrollmentStatusType =
  (typeof YunoEnrollmentStatus)[keyof typeof YunoEnrollmentStatus]

export type YunoEnrollmentErrorType =
  | (typeof YunoEnrollmentError)[keyof typeof YunoEnrollmentError]
  | any

export type YunoEnrollmentSDKResponse = {
  status: YunoEnrollmentErrorType
  vaultedToken?: string
  error?: YunoEnrollmentErrorType
}

/* Payment */
export const YunoPaymentStatus = {
  APPROVED: 'APPROVED',
  CREATED: 'CREATED',
  READY_TO_PAY: 'READY_TO_PAY',
  PENDING: 'PENDING',
  VERIFIED: 'VERIFIED',
  EXPIRED: 'EXPIRED',
  DECLINED: 'DECLINED',
  SUCCEEDED: 'SUCCEEDED',
  REFUNDED: 'REFUNDED',
  CANCELED: 'CANCELED',
  ERROR: 'ERROR',
} as const

export type YunoPaymentStatusType = (typeof YunoPaymentStatus)[keyof typeof YunoPaymentStatus]

export const YunoPaymentState = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
} as const

export type YunoPaymentStateType = (typeof YunoPaymentState)[keyof typeof YunoPaymentState]

export const YunoPaymentSubStatus = {
  // UNKNOWN for not mapped subStatus
  UNKNOWN: 'UNKNOWN',
  SUCCEEDED: 'SUCCEEDED',
  AUTHORIZED: 'AUTHORIZED',

  // Payment did not happen
  DECLINED_BY_BANK: 'DECLINED_BY_BANK',
  DO_NOT_HONOR: 'DO_NOT_HONOR',
  // Generic payment errors
  ERROR: 'ERROR',
  INVALID_MERCHANT: 'INVALID_MERCHANT',
  INVALID_TRANSACTION: 'INVALID_TRANSACTION',
  INVALID_ISSUER: 'INVALID_ISSUER',
  ACQUIRE_CONTINGENCY: 'ACQUIRE_CONTINGENCY',
  BAD_FILLED_INFO: 'BAD_FILLED_INFO',
  BANK_NOT_SUPPORTED: 'BANK_NOT_SUPPORTED',
  UNSUPPORTED_OPERATION: 'UNSUPPORTED_OPERATION',
  REPORTED_LOST: 'REPORTED_LOST',
  REPORTED_STOLEN: 'REPORTED_STOLEN',
  INSUFFICIENT_FOUNDS: 'INSUFFICIENT_FOUNDS',
  DENIED: 'DENIED',
  USER_RESTRICTION: 'USER_RESTRICTION',
  TERMINAL_ERROR: 'TERMINAL_ERROR',
  INVALID_AMOUNT: 'INVALID_AMOUNT',
  FRAUD_VALIDATION: 'FRAUD_VALIDATION',
  CALL_FOR_AUTHORIZE: 'CALL_FOR_AUTHORIZE',
  FIRST_USE: 'FIRST_USE',
  DISABLED: 'DISABLED',
  REJECTED_3D_SECURE_REQUIRED: 'REJECTED_3D_SECURE_REQUIRED',
  INVALID_ACCOUNT: 'INVALID_ACCOUNT',
  CHECKING_ACCOUNT: 'CHECKING_ACCOUNT',
  REJECTED_SAVING_ACCOUNT: 'REJECTED_SAVING_ACCOUNT',
} as const

export type YunoPaymentSubStatusType =
  (typeof YunoPaymentSubStatus)[keyof typeof YunoPaymentSubStatus]

export type YunoPaymentSDKResponse = {
  status: string // YunoPaymentStatusType
  subStatus: string // YunoPaymentSubStatusType
  sdkRequiredAction: boolean
  error?: YunoEnrollmentErrorType
  httpStatusCode: number
}

export type YunoConfirmation = {
  state: YunoPaymentStateType
  status: string // YunoPaymentStatusType
  subStatus: string // YunoPaymentSubStatusType
}

export const yunoSDKCustomizeStyles = `
  .Yuno-card-icon-bar__content {
    display: none;
  }
  fieldset.Yuno-text-field__content.focus label {
    color: #292929;
  }
  fieldset.Yuno-text-field__content.focus input {
    border: 1px solid #292929;
  }
  .Yuno-select-field__select-button {
    color: #292929 !important;
  }
  div.Yuno-select-field__state-select-wrapper.focus label {
    color: #292929;
  }
  div.Yuno-select-field__state-select-wrapper.focus button.Yuno-select-field__select-button {
    border: 1px solid #292929;
  }
  .Yuno-select-field__list * {
    color: #292929;
  }
  .Yuno-select-field__list .Yuno-option__option[aria-selected="true"]{
    color: #292929;
    background: #FFBC0D;
  }
  div.Yuno-button-step__content button {
    color: #292929 !important;
    border: 1px solid #ECEFF2;
    font-size: 0.875rem;
  }
  .Yuno-card-step__content input[type='checkbox'][checked]::before {
    background: white !important;
  }
  .Yuno-card-step__content input[type='checkbox'][checked]::after {
    width: 105%;
    height: 105%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjUgMEgxLjVDMS4xMDIxOCAwIDAuNzIwNjQ0IDAuMTU4MDM1IDAuNDM5MzQgMC40MzkzNEMwLjE1ODAzNSAwLjcyMDY0NCAwIDEuMTAyMTggMCAxLjVWMTYuNUMwIDE2Ljg5NzggMC4xNTgwMzUgMTcuMjc5NCAwLjQzOTM0IDE3LjU2MDdDMC43MjA2NDQgMTcuODQyIDEuMTAyMTggMTggMS41IDE4SDE2LjVDMTYuODk3OCAxOCAxNy4yNzk0IDE3Ljg0MiAxNy41NjA3IDE3LjU2MDdDMTcuODQyIDE3LjI3OTQgMTggMTYuODk3OCAxOCAxNi41VjEuNUMxOCAxLjEwMjE4IDE3Ljg0MiAwLjcyMDY0NCAxNy41NjA3IDAuNDM5MzRDMTcuMjc5NCAwLjE1ODAzNSAxNi44OTc4IDAgMTYuNSAwWk0xMy42NDA2IDcuMjkzNzVMOC4xNDY4OCAxMi41NDM4QzguMDA0ODUgMTIuNjc3NCA3LjgxNjg3IDEyLjc1MTIgNy42MjE4OCAxMi43NUM3LjQyOTc1IDEyLjc1MDcgNy4yNDQ4NyAxMi42NzY4IDcuMTA2MjUgMTIuNTQzOEw0LjM1OTM4IDkuOTE4NzVDNC4yODMxOSA5Ljg1MjI4IDQuMjIxMjMgOS43NzEwOSA0LjE3NzIyIDkuNjgwMDZDNC4xMzMyIDkuNTg5MDMgNC4xMDgwNSA5LjQ5MDA1IDQuMTAzMjggOS4zODkwNUM0LjA5ODUgOS4yODgwNSA0LjExNDE5IDkuMTg3MTMgNC4xNDk0MSA5LjA5MjM2QzQuMTg0NjMgOC45OTc1OCA0LjIzODY1IDguOTEwOSA0LjMwODIyIDguODM3NTRDNC4zNzc3OSA4Ljc2NDE3IDQuNDYxNDggOC43MDU2MyA0LjU1NDI2IDguNjY1NDNDNC42NDcwMyA4LjYyNTIzIDQuNzQ2OTcgOC42MDQyIDQuODQ4MDggOC42MDM2MkM0Ljk0OTE5IDguNjAzMDMgNS4wNDkzNyA4LjYyMjg5IDUuMTQyNjEgOC42NjIwMUM1LjIzNTg0IDguNzAxMTMgNS4zMjAyMSA4Ljc1ODcgNS4zOTA2MiA4LjgzMTI1TDcuNjIxODggMTAuOTU5NEwxMi42MDk0IDYuMjA2MjVDMTIuNzU1MiA2LjA3OTAyIDEyLjk0NDYgNi4wMTMwOSAxMy4xMzc5IDYuMDIyMjNDMTMuMzMxMiA2LjAzMTM4IDEzLjUxMzUgNi4xMTQ5IDEzLjY0NjcgNi4yNTUzM0MxMy43Nzk4IDYuMzk1NzYgMTMuODUzNSA2LjU4MjIyIDEzLjg1MjQgNi43NzU3NUMxMy44NTEzIDYuOTY5MjggMTMuNzc1NCA3LjE1NDg4IDEzLjY0MDYgNy4yOTM3NVoiIGZpbGw9IiNGRkJDMEQiLz4KPC9zdmc+Cg==) !important;
  }
  div.Yuno-button-step__content button:hover {
    opacity: 0.9 !important;
    background: white;
  }
  div.Yuno-button-step__content button[disabled] {
    background: #DBDAD7 !important;
    border: 1px solid #DBDAD7 !important;
    color: #b7b7b6 !important;
    pointer-events: none;
  }
  div.Yuno-button-step__content button.Yuno-button-step__next {
    background: #FFBC0D;
    border: 1px solid #C08B00;
  }
  div.Yuno-button-step__content button.Yuno-button-step__next:hover {
    background: #FFBC0D;
    opacity: 0.9 !important;
  }
  // legacy form
  .Yuno-card-form__submit button {
    background-color: #ffbc0d !important;
    color: #000 !important;
  }
  .Yuno-card-form__submit button[disabled] {
    opacity: 0.5;
  }
  div.Yuno-security-code-form__btn-submit button[disabled] {
    background: #DBDAD7 !important;
    border: 1px solid #DBDAD7 !important;
    color: #b7b7b6 !important;
    pointer-events: none;
  }
  div.Yuno-security-code-form__btn-submit button {
    background: #FFBC0D;
    border: 1px solid #C08B00;
    color: #000000;
    font-size: 0.875rem;
  }
  div.Yuno-security-code-form__btn-submit button:hover {
    background: #FFBC0D;
    opacity: 0.9 !important;
  }
  div.Yuno-security-code-form__btn-submit button:hover {
    background: #FFBC0D;
    opacity: 0.9 !important;
  }
  // Form Click to Pay
  .Yuno-fieldset__box .Yuno-input__label{
    color: #C1C1C1;
  }
`
